import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";

import { BREAKPOINTS, MESSAGES } from "@/constants";
import useBreakpoint from "@/hooks/useBreakpoint";
import useWebView from "@/hooks/useWebView";

import styles from "./ResponsiveVideo.module.scss";

const ResponsiveVideo = ({
  autoplay,
  className,
  controls,
  defaultVideo,
  hasAudio,
  hgVideo,
  lazy,
  lgVideo,
  loop,
  mdVideo,
  smVideo,
  title,
  xlVideo,
  xsVideo,
}) => {
  const { currentBreakpoint } = useBreakpoint();
  const videoRef = useRef(null);
  const [isInView, setIsInView] = useState(!lazy);

  const isWebView = useWebView();
  const [isMuted, setIsMuted] = useState(!isWebView);

  useEffect(() => {
    const currentVideoRef = videoRef?.current;
    let observer;

    if (currentVideoRef) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      });

      observer.observe(currentVideoRef);
    }

    return () => {
      observer.unobserve(currentVideoRef);
    };
  }, []);

  const videosByBreakpointLabel = {
    [BREAKPOINTS.XS.label]: xsVideo,
    [BREAKPOINTS.SM.label]: smVideo,
    [BREAKPOINTS.MD.label]: mdVideo,
    [BREAKPOINTS.LG.label]: lgVideo,
    [BREAKPOINTS.XL.label]: xlVideo,
    [BREAKPOINTS.HG.label]: hgVideo,
  };

  const videoUrlForBreakpoint = (currentBreakpoint) => {
    if (!isInView || !currentBreakpoint) {
      return null;
    }

    let videoUrl = defaultVideo?.fields?.file?.url;

    BREAKPOINTS.default.some((breakpoint) => {
      if (
        breakpoint.value <= currentBreakpoint?.value &&
        videosByBreakpointLabel[breakpoint.label]
      ) {
        videoUrl = videosByBreakpointLabel[breakpoint.label]?.fields.file.url;
        return true;
      }
    });

    return videoUrl;
  };

  return (
    <figure className={classNames(styles.responsiveVideo, className)}>
      <video
        autoPlay={autoplay}
        controls={isWebView || controls}
        loop={loop}
        muted={isMuted}
        playsInline={true}
        preload={(!lazy).toString()}
        ref={videoRef}
        src={videoUrlForBreakpoint(currentBreakpoint)}
        title={title}
        type={defaultVideo?.fields?.file?.contentType}
      />

      {hasAudio && (
        <button
          className={styles.muteToggle}
          name={`ResponsiveVideo - ${isMuted ? "VolumeOff" : "VolumeHigh"}`}
          onClick={() => setIsMuted(!isMuted)}
          title={`${isMuted ? "Unm" : "M"}ute video sound`}
        >
          {isMuted ? <IoIosVolumeOff /> : <IoIosVolumeHigh />}
        </button>
      )}
    </figure>
  );
};

ResponsiveVideo.defaultProps = {
  autoplay: true,
  controls: false,
  hasAudio: false,
  lazy: true,
  loop: true,
  title: MESSAGES.TAGLINE,
};

ResponsiveVideo.propTypes = {
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  controls: PropTypes.bool,
  defaultVideo: PropTypes.object.isRequired,
  hasAudio: PropTypes.bool,
  hgVideo: PropTypes.object,
  lazy: PropTypes.bool,
  lgVideo: PropTypes.object,
  loop: PropTypes.bool,
  mdVideo: PropTypes.object,
  smVideo: PropTypes.object,
  title: PropTypes.string,
  xlVideo: PropTypes.object,
  xsVideo: PropTypes.object,
};

export default ResponsiveVideo;
